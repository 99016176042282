import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import { navigate } from "gatsby";
import { ErrorMessage, Field, Form, Formik } from "formik";
import CartContext from "../../context/cart-context";
import states from "../../data/states";
import CreditCardLogos from "../images/credit-card-logos";
import { Helmet } from "react-helmet";
import { usePostHog } from "posthog-js/react";

const QuoteSummaryBilling = ({
  totals,
  quoteItems,
  emitGoToTab,
  emitSubmitted,
}) => {
  const cart = useContext(CartContext);
  const posthog = usePostHog();
  const { checkoutInfo, resetCart, setCheckoutInfo } = useContext(CartContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formRef = useRef();

  const swiftUserId = useMemo(() => {
    if (typeof window === "undefined") {
      return null;
    }
    return localStorage.getItem("swiftUserId");
  }, []);

  const getCardLastFour = (transactionDetails) => {
    if (transactionDetails?.card?.number) {
      return transactionDetails.card.number.substring(12);
    }

    if (transactionDetails?.wallet?.card?.number) {
      return transactionDetails.wallet?.card?.number?.substring(12);
    }

    return "";
  };

  const handleSubmit = (transactionDetails) => {
    try {
      posthog.capture("user_submit_order_attempted", {
        firstName: checkoutInfo.firstName,
        lastName: checkoutInfo.lastName,
        email: checkoutInfo.email,
        phone: checkoutInfo.phone,
        cartContents: quoteItems,
        cartTotals: totals,
        page: "quote builder",
        discounts: totals.freeLaddersOrLocksDiscount
          ? {
              name: "Free Ladders Or Locks Promotion",
              code: "freeLaddersOrLocksDiscount",
              cart_or_item_discount: "cart",
              percent_or_amount_off: "amount",
              amount: totals.freeLaddersOrLocksDiscount,
            }
          : null,
      });
    } catch {
      console.error("Posthog not available");
    }

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.RECAPTCHA_SITE_KEY, {
          action: "checkout",
        })
        .then(async (token) => {
          const cookies =
            typeof document !== "undefined" ? document.cookie : [];

          const serverData = {
            ...checkoutInfo,
            dataValue: transactionDetails,
            amount: totals.dueNow.substring(1),
            contents: quoteItems,
            totals: totals,
            callInfo: checkoutInfo.callInfo
              ? JSON.parse(checkoutInfo.callInfo)
              : {},
            cookies,
            recaptchaToken: token,
            referralId: sessionStorage.getItem("referralId"),
            discounts: totals.freeLaddersOrLocksDiscount
              ? {
                  name: "Free Ladders Or Locks Promotion",
                  code: "freeLaddersOrLocksDiscount",
                  cart_or_item_discount: "cart",
                  percent_or_amount_off: "amount",
                  amount: totals.freeLaddersOrLocksDiscount,
                }
              : null,
            salesRepId: swiftUserId,
          };
          serverData.cardLastFour = getCardLastFour(transactionDetails);
          if (process.env.NODE_ENV === "development") {
            // Simulate a successful response for development
            setIsSubmitting(false);
            navigate(
              `/checkout-success?email=${encodeURIComponent(
                cart.checkoutInfo.email
              )}&phone=${encodeURIComponent(cart.checkoutInfo.phone)}/`,
              {
                state: {
                  transactionData: {}, // Fill with any mocked data you need
                  amountCharged: totals.dueNow,
                  tax: totals.tax,
                  cartContents: quoteItems,
                  cartTotals: totals,
                  checkoutInfo: checkoutInfo,
                  email: checkoutInfo.email,
                  callInfo: checkoutInfo.callInfo
                    ? JSON.parse(checkoutInfo.callInfo)
                    : {},
                },
              }
            );
            emitSubmitted();
            resetCart();
          } else {
            axios
              .post(process.env.PAYMENT_CAPTURE_URL, serverData)
              .then((chargeResponse) => {
                setIsSubmitting(false);
                if (
                  chargeResponse.data.success ||
                  chargeResponse.data.data.responsetext === "Approved"
                ) {
                  navigate(
                    `/checkout-success?email=${encodeURIComponent(
                      cart.checkoutInfo.email
                    )}&phone=${encodeURIComponent(cart.checkoutInfo.phone)}/`,
                    {
                      state: {
                        transactionData: chargeResponse.data.data,
                        amountCharged: totals.dueNow,
                        tax: totals.tax,
                        cartContents: quoteItems,
                        cartTotals: totals,
                        checkoutInfo: checkoutInfo,
                        email: checkoutInfo.email,
                        callInfo: checkoutInfo.callInfo
                          ? JSON.parse(checkoutInfo.callInfo)
                          : {},
                      },
                    }
                  );
                  emitSubmitted();
                  resetCart();
                } else {
                  alert(chargeResponse.data.data.responsetext);
                }
              })
              .catch((error) => {
                console.error(error);
                alert(
                  `We are having an issue contacting our payment provider. Please call us to place your order. ${process.env.PHONE_NUMBER}`
                );
                setIsSubmitting(false);
              });
          }
        });
    });
  };

  const [state, setState] = useState({
    useDifferentAddress: false,
    shippingZip: checkoutInfo.shippingZip,
    shippingAddress: checkoutInfo.shippingAddress,
    shippingMethod: checkoutInfo.shippingMethod,
    billingFirstName: checkoutInfo.firstName,
    billingLastName: checkoutInfo.lastName,
    billingAddress: checkoutInfo.shippingAddress,
    billingAddress2: checkoutInfo.shippingAddress2,
    billingCity: checkoutInfo.shippingCity,
    billingState: checkoutInfo.shippingState,
    billingZip: checkoutInfo.shippingZip,
    transactionDetails: {},
    ccnumberValid: false,
    ccexpValid: false,
    cvvValid: false,
    showDebugInfo: false,
  });

  useEffect(() => {
    if (!checkoutInfo) return;

    if (typeof sessionStorage !== "undefined") {
      axios
        .post(`/.netlify/functions/start-checkout`, {
          email: checkoutInfo.email,
          checkoutInfo: checkoutInfo,
          cartTotals: totals,
          cartContents: quoteItems,
          sourceUrl: sessionStorage.getItem("bpd-first-page"),
          referrer: sessionStorage.getItem("bpd-referrer"),
          value: totals?.total ? +totals.total?.substr(1) : 0,
          gclid: localStorage.getItem("bpd-gclid"),
          fbclid: localStorage.getItem("bpd-fbclid"),
          utm_source: localStorage.getItem("utm_source"),
          utm_medium: localStorage.getItem("utm_medium"),
          utm_campaign: localStorage.getItem("utm_campaign"),
          msclkid: localStorage.getItem("msclkid"),
        })
        .then((response) => {
          if (response.data?.klaviyo?.cart_id) {
            localStorage.setItem(
              "bpd-cart-id",
              response.data?.klaviyo?.cart_id
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [checkoutInfo, totals, quoteItems]);

  useEffect(() => {
    const lineItems = quoteItems?.map((item) => {
      return {
        label: item.title,
        amount: Number(item.salePrice || item.basePrice).toFixed(2),
      };
    });

    if (typeof window !== "undefined" && totals.total) {
      const collectJSConfig = {
        callback: paymentCallback,
        paymentSelector: "#payButton",
        variant: "inline",
        googleFont: "Abel",
        invalidCss: {
          color: "#B40E3E",
        },
        validCss: {
          color: "#13AA73",
        },
        customCss: {
          "border-color": "#ffffff",
          "border-style": "solid",
        },
        focusCss: {
          "border-color": "#1AD18E",
          "border-style": "solid",
          "border-width": "3px",
        },
        fields: {
          ccnumber: {
            selector: "#ccnumber",
            title: "Card Number",
            placeholder: "0000 0000 0000 0000",
          },
          ccexp: {
            selector: "#ccexp",
            title: "Card Expiration",
            placeholder: "mm/yy",
          },
          cvv: {
            display: "show",
            selector: "#cvv",
            title: "CVV Code",
            placeholder: "***",
          },
          googlePay: {
            selector: "#googlePayButton",
            shippingAddressRequired: false,
            shippingAddressParameters: {
              phoneNumberRequired: false,
              allowedCountryCodes: ["US"],
            },
            billingAddressRequired: true,
            billingAddressParameters: {
              phoneNumberRequired: false,
              format: "MIN",
            },
            emailRequired: true,
            buttonType: "buy",
            buttonColor: "default",
            buttonLocale: "en",
          },
          applePay: {
            selector: "#applePayButton",
            requiredBillingContactFields: ["postalAddress", "name"],
            requiredShippingContactFields: ["postalAddress", "name"],
            contactFields: ["phone", "email"],
            contactFieldsMappedTo: "shipping",
            lineItems,
            shippingMethods: [
              {
                label: "Selected Previously",
                amount: "0.00",
                detail: "The shipping method you selected previously",
                identifier: "standardShipping",
              },
            ],
            totalLabel: "Total",
            totalType: "pending",
            type: "buy",
            style: {
              "button-style": "white-outline",
              height: "50px",
              "border-radius": "0",
            },
          },
        },
        price: totals.total.substring(1),
        currency: "USD",
        country: "US",
        validationCallback: validationCallback,
        timeoutDuration: 3000,
        timeoutCallback: timeoutCallback,
      };

      window.CollectJS.configure(collectJSConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totals.total, quoteItems]);

  const paymentCallback = (response) => {
    setState((s) => ({ ...s, transactionDetails: response }));
    if (response.token) {
      handleSubmit(response);
    } else {
      alert(
        "There is a problem with your card. Please check the info and try again"
      );
      setIsSubmitting(false);
    }
  };

  const validationCallback = (field, status) => {
    const stateKey = `${field}Valid`;
    setState((s) => ({ ...s, [stateKey]: status }));
  };

  const timeoutCallback = () => {
    alert(
      `We're having an issue processing your card. Please check your payment details. To place your order by phone call us at ${process.env.PHONE_NUMBER}`
    );
    setIsSubmitting(false);
  };

  const toggleAddressType = (e) => {
    const checkoutInfo =
      typeof window !== `undefined`
        ? JSON.parse(localStorage.getItem("cartValues")).checkoutInfo
        : {};

    const useDifferentAddress = e.target.checked || false;

    setState((s) => ({
      ...s,
      useDifferentAddress: useDifferentAddress,
    }));

    if (useDifferentAddress) {
      setState((s) => ({
        ...s,
        billingFirstName: "",
        billingLastName: "",
        billingAddress: "",
        billingAddress2: "",
        billingCity: "",
        billingState: "",
        billingZip: "",
      }));
    } else {
      setState((s) => ({
        ...s,
        billingFirstName: checkoutInfo.firstName,
        billingLastName: checkoutInfo.lastName,
        billingAddress: checkoutInfo.shippingAddress,
        billingAddress2: checkoutInfo.shippingAddress2,
        billingCity: checkoutInfo.shippingCity,
        billingState: checkoutInfo.shippingState,
        billingZip: checkoutInfo.shippingZip,
      }));
    }
  };

  const checkAddress = () => {
    if (!state.useDifferentAddress) {
      return true;
    }

    if (
      !state.billingFirstName ||
      !state.billingLastName ||
      !state.billingAddress ||
      !state.billingCity ||
      !state.billingState ||
      !state.billingZip
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_SITE_KEY}`}
        ></script>
      </Helmet>

      <Formik
        innerRef={formRef}
        initialValues={{
          billingFirstName: state.billingFirstName,
          billingLastName: state.billingLastName,
          billingAddress: state.billingAddress,
          billingAddress2: state.billingAddress2,
          billingCity: state.billingCity,
          billingState: state.billingState,
          billingZip: state.billingZip,
        }}
        validate={(values) => {
          setState((s) => ({
            ...s,
            billingFirstName: values.billingFirstName,
            billingLastName: values.billingLastName,
            billingAddress: values.billingAddress,
            billingAddress2: values.billingAddress2,
            billingCity: values.billingCity,
            billingState: values.billingState,
            billingZip: values.billingZip,
          }));
          let errors = {};
          if (!values.billingFirstName) {
            errors.billingFirstName = "Required";
          }
          if (!values.billingLastName) {
            errors.billingLastName = "Required";
          }
          if (!values.billingAddress) {
            errors.billingAddress = "Required";
          }
          if (!values.billingCity) {
            errors.billingCity = "Required";
          }
          if (!values.billingState) {
            errors.billingState = "Required";
          }
          if (!values.billingZip) {
            errors.billingZip = "Required";
          }

          setCheckoutInfo({
            ...checkoutInfo,
            referrer: sessionStorage.getItem("bpd-referrer"),
            firstPage: sessionStorage.getItem("bpd-first-page"),
            gclid: localStorage.getItem("bpd-gclid"),
            fbclid: localStorage.getItem("bpd-fbclid"),
            utm_source: localStorage.getItem("bpd-utm_source"),
            utm_medium: localStorage.getItem("bpd-utm_medium"),
            utm_campaign: localStorage.getItem("bpd-utm_campaign"),
            msclkid: localStorage.getItem("bpd-msclkid"),
            marketingCampaignInfo: localStorage.getItem(
              "marketingCampaignInfo"
            ),
            taxRate: process.env.TAX_RATE,
            ...values,
          });

          return errors;
        }}
        onSubmit={(values) => {
          setCheckoutInfo((ci) => ({
            ...ci,
            ...values,
          }));
          handleSubmit();
        }}
        enableReinitialize={true}
      >
        {() => {
          return (
            <Form>
              <div className={isSubmitting ? "submitting-payment" : "hidden"}>
                Securely processing order details...
              </div>
              <div className={state.useDifferentAddress ? "hidden" : "mb-4"}>
                <h4 className="text-grey-darkest">
                  {state.billingFirstName} {state.billingLastName}
                </h4>
                <p className="text-grey-darkest">
                  {state.billingAddress}
                  <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: state.billingAddress2
                        ? `${state.billingAddress2}<br />`
                        : "",
                    }}
                  />
                  {state.billingCity}, {state.billingState} {state.billingZip}
                </p>
              </div>
              <div>
                <div className="flex flex-wrap w-full items-stretch">
                  <div className="w-full mb-6">
                    <label
                      htmlFor="useDifferentBillingAddress"
                      className="block mb-2"
                    >
                      Use a different billing address
                      <abbr className="required" title="Required">
                        *
                      </abbr>
                      <input
                        checked={!!state.useDifferentAddress}
                        value={!!state.useDifferentAddress}
                        className="ml-4 px-12 focus:outline-none focus:shadow"
                        id="useDifferentBillingAddress"
                        name="useDifferentBillingAddress"
                        onChange={toggleAddressType}
                        type="checkbox"
                      />
                    </label>
                  </div>
                </div>
                <div
                  className={`${
                    !state.useDifferentAddress
                      ? "hidden"
                      : "grid grid-cols-1 md:grid-cols-2 gap-4"
                  } w-full items-stretch`}
                >
                  <div className="mb-6">
                    <label htmlFor="billingFirstName" className="block mb-2">
                      First Name
                      <abbr className="required" title="Required">
                        *
                      </abbr>
                    </label>
                    <Field
                      type="text"
                      name="billingFirstName"
                      id="billingFirstName"
                      placeholder="First Name"
                      className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                    />
                    <ErrorMessage
                      className="text-red-dark text-sm mt-2"
                      name="billingFirstName"
                      component="div"
                    />
                  </div>
                  <div className="mb-6">
                    <label htmlFor="billingLastName" className="block mb-2">
                      Last Name
                      <abbr className="required" title="Required">
                        *
                      </abbr>
                    </label>
                    <Field
                      type="text"
                      name="billingLastName"
                      id="billingLastName"
                      placeholder="Last Name"
                      className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                    />
                    <ErrorMessage
                      className="text-red-dark text-sm mt-2"
                      name="billingLastName"
                      component="div"
                    />
                  </div>

                  <div className="mb-6">
                    <Field
                      type="text"
                      name="billingAddress"
                      placeholder="Billing Address"
                      className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                    />
                    <ErrorMessage
                      className="text-red-dark text-sm mt-2"
                      name="billingAddress"
                      component="div"
                    />
                  </div>

                  <div className="mb-6">
                    <Field
                      type="text"
                      name="billingAddress2"
                      placeholder="Line 2"
                      className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                    />
                    <ErrorMessage
                      className="text-red-dark text-sm mt-2"
                      name="billingAddress2"
                      component="div"
                    />
                  </div>

                  <div className="mb-6">
                    <label htmlFor="billingCity" className="block mb-2">
                      City
                      <abbr className="required" title="Required">
                        *
                      </abbr>
                    </label>
                    <Field
                      type="text"
                      name="billingCity"
                      placeholder="City"
                      className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                    />
                    <ErrorMessage
                      className="text-red-dark text-sm mt-2"
                      name="billingCity"
                      component="div"
                    />
                  </div>

                  <div className="mb-6 flex gap-4 items-stretch">
                    <div>
                      <label htmlFor="billingState" className="block mb-2">
                        State
                        <abbr className="required" title="Required">
                          *
                        </abbr>
                      </label>
                      <Field
                        component="select"
                        name="billingState"
                        className="bg-white appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                      >
                        <option value="" aria-label="N/A" key="na" />
                        {states.map((state, index) => {
                          return (
                            <option value={state.abbreviation} key={index}>
                              {state.name}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        className="text-red-dark text-sm mt-2"
                        name="billingState"
                        component="div"
                      />
                    </div>

                    <div className="flex-1">
                      <label htmlFor="billingZip" className="block mb-2">
                        Zip
                        <abbr className="required" title="Required">
                          *
                        </abbr>
                      </label>
                      <Field
                        type="text"
                        name="billingZip"
                        placeholder="84000"
                        className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                      />
                      <ErrorMessage
                        className="text-red-dark text-sm mt-2"
                        name="billingZip"
                        component="div"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-grey-lighter rounded-lg p-4 md:py-8 md:px-8 mb-4">
                <div className="flex mb-4 items-center gap-6">
                  <h3 className="text-lg lg:text-xl">Credit Card</h3>
                  <CreditCardLogos />
                  <div className="flex-1 flex flex-wrap items-center justify-end gap-2">
                    <div id="applePayButton"></div>
                    <div id="googlePayButton"></div>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                  <div>
                    <label htmlFor="ccnumber" className="block mb-2">
                      Credit Card Number
                      <abbr className="required" title="Required">
                        *
                      </abbr>
                    </label>
                    <div
                      className="paymentField bg-white appearance-none border border-grey rounded w-full text-grey-darker leading-tight focus:outline-none focus:ring text-base"
                      id="ccnumber"
                    ></div>
                  </div>

                  <div>
                    <label htmlFor="ccexp" className="block mb-2">
                      Expiration
                      <abbr className="required" title="Required">
                        *
                      </abbr>
                    </label>
                    <div
                      className="paymentField bg-white appearance-none border border-grey rounded w-full text-grey-darker leading-tight focus:outline-none focus:ring text-base"
                      id="ccexp"
                    ></div>
                  </div>

                  <div>
                    <label htmlFor="cvv" className="block mb-2">
                      CVC Number
                      <abbr className="required" title="Required">
                        *
                      </abbr>
                    </label>
                    <div
                      className="paymentField bg-white appearance-none border border-grey rounded w-full text-grey-darker leading-tight focus:outline-none focus:ring text-base"
                      id="cvv"
                    ></div>
                  </div>
                </div>
              </div>

              <div className="my-6">
                <ol className="list-decimal mb-6">
                  <li>
                    <div className="flex flex-wrap items-center justify-between mb-3">
                      Start my order, place deposit{" "}
                      <span
                        className={`font-semibold text-${process.env.THEME_COLOR_PRIMARY}-dark`}
                      >
                        (Collected today) {totals.dueNow}
                      </span>
                    </div>
                  </li>
                  {!!totals.dueAtMeasure && (
                    <li>
                      <div className="flex flex-wrap items-center justify-between mb-3">
                        50% of remaining balance{" "}
                        <span>
                          (Collected after measurement) {totals.dueAtMeasure}
                        </span>
                      </div>
                    </li>
                  )}
                  {!!totals.dueAtInstall && (
                    <li>
                      <div className="flex flex-wrap items-center justify-between mb-3">
                        Remaining balance{" "}
                        <span>
                          {" "}
                          (Collected after install) {totals.dueAtInstall}
                        </span>
                      </div>
                    </li>
                  )}
                </ol>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className="mb-6 flex gap-3">
        <button
          onClick={() => {
            emitGoToTab("installation");
          }}
          className="block md:inline-block bg-grey text-white text-sm px-4 py-3 no-underline rounded uppercase"
        >
          Previous
        </button>
        <button
          id="payButton"
          className={`bg-${process.env.THEME_COLOR_PRIMARY} text-white text-sm px-4 py-3 no-underline rounded uppercase`}
          onClick={() => {
            setIsSubmitting(true);
          }}
          type="submit"
        >
          Place {totals.dueNow} deposit
        </button>
      </div>
      <p className="text-grey-dark text-sm">
        By placing this deposit, the customer acknowledges and authorizes us to
        charge their credit card for the 50% payment after measurements are
        taken and the remaining balance upon installation. As this product is
        custom-tailored to fit the specific needs of your property, all sales
        are final and non-refundable
      </p>
      {state.showDebugInfo && (
        <div>
          <p>ccnumberValid: {state.ccnumberValid ? "yes" : "no"}</p>
          <p>ccexpValid: {state.ccexpValid ? "yes" : "no"}</p>
          <p>cvvValid: {state.cvvValid ? "yes" : "no"}</p>
          <p>addressValid: {checkAddress(false) ? "yes" : "no"}</p>
        </div>
      )}
    </>
  );
};
export default QuoteSummaryBilling;
